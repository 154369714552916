import { Header } from '@/components/tailwind/header'
import { Footer } from '@/components/tailwind/footer'
import { Seo } from '@/components/tailwind/seo'
import React from 'react'

import { useSiteConfig } from '@/hooks/use-site-config'
import { CartSidebar } from '@/components/tailwind/cart-sidebar'

type LayoutTailwindMDX<T = any> = React.FC<T>

export const LayoutTailwindMDX: LayoutTailwindMDX = ({ children }) => {
  const siteConfig = useSiteConfig()

  return (
    <>
      <Seo />
      <header>
        <Header
          float={false}
          transparent={false}
          dark={true}
          background={`bg-gray-900`}
          {...siteConfig}
        />
        <CartSidebar />
      </header>
      <main className={`container mx-auto px-5 py-10 sm:px-0`}>
        <div className={`prose`}>{children}</div>
      </main>
      <Footer {...siteConfig} />
    </>
  )
}

export default LayoutTailwindMDX
